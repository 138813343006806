import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { RootState } from 'redux/store';

interface ISavingsProps {
    phase: string;
    currentBill?: number;
    capacity: number;
    kWhPerMonth: number;
    estSavingsPerYear: number;
    ROI: number;
    estSavingsPerMonth: number;
    estSavingsIn10Years: number;
    state: string;
    postalCode: string;
    setFieldValue: (name: string, value: any) => void;
}

const getROIWithState = (phase: string, currentBill: number | undefined, state: string, postalCode: string) => {
    let ROI = 0;
    if (phase === 'single') {
        if (currentBill && currentBill <= 367) {
            if (state === 'Kuala Lumpur' || state === 'Selangor' || state === 'Putrajaya' || postalCode === '71800' || postalCode === '71809' || postalCode === '71807') {
                ROI = 5.0;
            } else if (state === 'Negeri Sembilan' || state === 'Melaka') {
                ROI = 5.6;
            } else if (state === 'Johor' || state === 'Pahang' || state === 'Perak' || state === 'Pulau Pinang') {
                ROI = 5.6;
            } else if (state === 'Kedah' || state === 'Perlis') {
                ROI = 5.9;
            } else if (state === 'Kelantan' || state === 'Terengganu') {
                ROI = 5.9;
            }
        } else if (currentBill && currentBill > 367) {
            if (state === 'Kuala Lumpur' || state === 'Selangor' || state === 'Putrajaya' || postalCode === '71800' || postalCode === '71809' || postalCode === '71807') {
                ROI = 4.2;
            } else if (state === 'Negeri Sembilan' || state === 'Melaka') {
                ROI = 4.6;
            } else if (state === 'Johor' || state === 'Pahang' || state === 'Perak' || state === 'Pulau Pinang') {
                ROI = 4.6;
            } else if (state === 'Kedah' || state === 'Perlis') {
                ROI = 4.8;
            } else if (state === 'Kelantan' || state === 'Terengganu') {
                ROI = 4.8;
            }
        }
    } else if (currentBill && currentBill <= 470) {
        if (state === 'Kuala Lumpur' || state === 'Selangor' || state === 'Putrajaya' || postalCode === '71800' || postalCode === '71809' || postalCode === '71807') {
            ROI = 4.8;
        } else if (state === 'Negeri Sembilan' || state === 'Melaka') {
            ROI = 5.2;
        } else if (state === 'Johor' || state === 'Pahang' || state === 'Perak' || state === 'Pulau Pinang') {
            ROI = 5.2;
        } else if (state === 'Kedah' || state === 'Perlis') {
            ROI = 5.4;
        } else if (state === 'Kelantan' || state === 'Terengganu') {
            ROI = 5.4;
        }
    } else if (currentBill && currentBill > 470 && currentBill <= 574) {
        if (state === 'Kuala Lumpur' || state === 'Selangor' || state === 'Putrajaya' || postalCode === '71800' || postalCode === '71809' || postalCode === '71807') {
            ROI = 4.2;
        } else if (state === 'Negeri Sembilan' || state === 'Melaka') {
            ROI = 4.7;
        } else if (state === 'Johor' || state === 'Pahang' || state === 'Perak' || state === 'Pulau Pinang') {
            ROI = 4.7;
        } else if (state === 'Kedah' || state === 'Perlis') {
            ROI = 5.0;
        } else if (state === 'Kelantan' || state === 'Terengganu') {
            ROI = 5.0;
        }
    } else if (currentBill && currentBill > 574 && currentBill <= 713) {
        if (state === 'Kuala Lumpur' || state === 'Selangor' || state === 'Putrajaya' || postalCode === '71800' || postalCode === '71809' || postalCode === '71807') {
            ROI = 3.8;
        } else if (state === 'Negeri Sembilan' || state === 'Melaka') {
            ROI = 4.1;
        } else if (state === 'Johor' || state === 'Pahang' || state === 'Perak' || state === 'Pulau Pinang') {
            ROI = 4.2;
        } else if (state === 'Kedah' || state === 'Perlis') {
            ROI = 4.4;
        } else if (state === 'Kelantan' || state === 'Terengganu') {
            ROI = 4.4;
        }
    } else if (currentBill && currentBill > 713 && currentBill <= 1063) {
        if (state === 'Kuala Lumpur' || state === 'Selangor' || state === 'Putrajaya' || postalCode === '71800' || postalCode === '71809' || postalCode === '71807') {
            ROI = 3.5;
        } else if (state === 'Negeri Sembilan' || state === 'Melaka') {
            ROI = 3.7;
        } else if (state === 'Johor' || state === 'Pahang' || state === 'Perak' || state === 'Pulau Pinang') {
            ROI = 3.8;
        } else if (state === 'Kedah' || state === 'Perlis') {
            ROI = 3.9;
        } else if (state === 'Kelantan' || state === 'Terengganu') {
            ROI = 3.9;
        }
    } else if (currentBill && currentBill > 1063) {
        if (state === 'Kuala Lumpur' || state === 'Selangor' || state === 'Putrajaya' || postalCode === '71800' || postalCode === '71809' || postalCode === '71807') {
            ROI = 3.2;
        } else if (state === 'Negeri Sembilan' || state === 'Melaka') {
            ROI = 3.5;
        } else if (state === 'Johor' || state === 'Pahang' || state === 'Perak' || state === 'Pulau Pinang') {
            ROI = 3.5;
        } else if (state === 'Kedah' || state === 'Perlis') {
            ROI = 3.6;
        } else if (state === 'Kelantan' || state === 'Terengganu') {
            ROI = 3.7;
        }
    }

    return ROI;
};
const Savings = (props: ISavingsProps): JSX.Element => {
    const { phase, currentBill, capacity, kWhPerMonth, estSavingsPerYear, ROI, estSavingsPerMonth, estSavingsIn10Years, state, postalCode, setFieldValue } = props;

    useEffect(() => {
        calculateSavings();
        if (state) {
            setFieldValue('ROI', getROIWithState(phase, currentBill, state, postalCode));
        }
    }, [phase, currentBill, capacity, state]);

    const calculateSavings = () => {
        if (!currentBill) {
            setFieldValue('kWhPerMonth', 0);
            setFieldValue('estSavingsPerMonth', 0);
            setFieldValue('estSavingsPerYear', 0);
            setFieldValue('estSavingsIn10Years', 0);
            setFieldValue('ROI', 0);
        } else if (phase === 'single') {
            if (currentBill <= 367) {
                setFieldValue('kWhPerMonth', 548);
                setFieldValue('estSavingsPerMonth', 298);
                setFieldValue('estSavingsPerYear', 3578);
                setFieldValue('estSavingsIn10Years', 35780);
                setFieldValue('ROI', 5);
            } else if (currentBill > 367) {
                setFieldValue('kWhPerMonth', 731);
                setFieldValue('estSavingsPerMonth', 398);
                setFieldValue('estSavingsPerYear', 4771);
                setFieldValue('estSavingsIn10Years', 47707);
                setFieldValue('ROI', 4.2);
            }
        } else if (currentBill <= 470) {
            setFieldValue('kWhPerMonth', 731);
            setFieldValue('estSavingsPerMonth', 398);
            setFieldValue('estSavingsPerYear', 4771);
            setFieldValue('estSavingsIn10Years', 47707);
            setFieldValue('ROI', 4.8);
        } else if (currentBill > 470 && currentBill <= 574) {
            setFieldValue('kWhPerMonth', 914);
            setFieldValue('estSavingsPerMonth', 497);
            setFieldValue('estSavingsPerYear', 5963);
            setFieldValue('estSavingsIn10Years', 59633);
            setFieldValue('ROI', 4.2);
        } else if (currentBill > 574 && currentBill <= 713) {
            setFieldValue('kWhPerMonth', 1157);
            setFieldValue('estSavingsPerMonth', 629);
            setFieldValue('estSavingsPerYear', 7554);
            setFieldValue('estSavingsIn10Years', 75535);
            setFieldValue('ROI', 3.8);
        } else if (currentBill > 713 && currentBill <= 1063) {
            setFieldValue('kWhPerMonth', 1462);
            setFieldValue('estSavingsPerMonth', 795);
            setFieldValue('estSavingsPerYear', 9541);
            setFieldValue('estSavingsIn10Years', 95413);
            setFieldValue('ROI', 3.5);
        } else if (currentBill > 1063) {
            setFieldValue('kWhPerMonth', 1705);
            setFieldValue('estSavingsPerMonth', 928);
            setFieldValue('estSavingsPerYear', 11132);
            setFieldValue('estSavingsIn10Years', 111315);
            setFieldValue('ROI', 3.2);
        } else {
            setFieldValue('kWhPerMonth', 0);
            setFieldValue('estSavingsPerMonth', 0);
            setFieldValue('estSavingsPerYear', 0);
            setFieldValue('estSavingsIn10Years', 0);
            setFieldValue('ROI', 0);
        }

        return true;
    };

    const valueCard = (title: string, content: string) => {
        return (
            <div className='savings-card-container'>
                <p className='font-medium text-center mb-2'>{title}</p>
                <div className='rounded-lg savings-card justify-center mb-5'>
                    {content}
                </div>
            </div>
        );
    };
    return (
        <div className='rounded-lg background-blue-2 py-8 px-4 justify-center mb-3'>
            <h3 className='font-bold text-center mb-8'>Savings with Solar PV System</h3>
            <div className='flex flex-row gap-4'>
                <div className='flex flex-wrap justify-between'>
                    {valueCard('Generated kWh Per Month', `${kWhPerMonth}`)}
                    {valueCard('Est. Saving Per Month', `RM ${estSavingsPerMonth.toLocaleString()}`)}
                    {valueCard('Est. Saving Per Year', `RM ${estSavingsPerYear.toLocaleString()}`)}
                    {valueCard('Est. Saving In 10 Years', `RM ${estSavingsIn10Years.toLocaleString()}`)}
                    {valueCard('Est. Return of Investment (ROI)', `${ROI} years`)}
                </div>
            </div>
        </div>
    );
};

Savings.defaultProps = {
    currentBill: 0,
};

const mapStateToProps = (state: RootState) => ({
});

export default connect(mapStateToProps)(Savings);
